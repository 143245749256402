import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from "@sentry/react";

let baseURL;

if (process.env.REACT_APP_APP_ENV === 'prd') {
  baseURL = 'https://api.fortifyedu.com/';
} else {
  baseURL = 'http://localhost:5000/';
}

// Detect Mobile Safari
const isIOSSafari = /iPad|iPhone|iPod/.test(navigator.userAgent) && 
                   !window.MSStream && 
                   /safari/i.test(navigator.userAgent);

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 30000;

const excludedEndpoints = ['/auth/login', '/auth/register', '/auth/refresh'];

// Add retry logic to axios instance
axiosRetry(axios, { 
  retries: isIOSSafari ? 3 : 2,
  retryDelay: (retryCount) => {
    if (retryCount === 1) return 100;  // First retry very quick
    return isIOSSafari ? retryCount * 500 : retryCount * 1000;
  },
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || 
           error.code === 'ECONNABORTED';
  }
});

// Enhanced error categorization
const categorizeNetworkError = (error) => {
  if (error.code === 'ECONNABORTED') {
    return 'TIMEOUT';
  }
  
  if (error.message === 'Network Error') {
    if (error.response === undefined && error.request) {
      if (error.request.status === 0) {
        return 'CORS';
      }
    }
    
    if (!error.request) {
      return 'REQUEST_SETUP_FAILED';
    }
    
    if (error.request && !error.response) {
      return 'CONNECTION_FAILED';
    }
  }
  
  return 'UNKNOWN';
};

// Enhanced network diagnostics
const gatherNetworkDiagnostics = () => {
  try {
    return {
      online: navigator.onLine,
      connectionType: navigator?.connection?.type,
      connectionSpeed: navigator?.connection?.effectiveType,
      pageLoadType: performance?.getEntriesByType('navigation')[0]?.type,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      vendor: navigator.vendor
    };
  } catch (e) {
    return {
      userAgent: navigator.userAgent,
      error: 'Failed to gather full diagnostics'
    };
  }
};

// Enhanced error logging
const logEnhancedNetworkError = (error, context = {}) => {
  const errorCategory = categorizeNetworkError(error);
  const diagnostics = gatherNetworkDiagnostics();
  
  const enhancedContext = {
    ...context,
    errorCategory,
    url: error.config?.url,
    method: error.config?.method,
    requestHeaders: error.config?.headers,
    requestData: error.config?.data,
    browserOnline: navigator.onLine,
    timestamp: new Date().toISOString(),
    ...diagnostics  // Add our new network diagnostics
  };

  Sentry.captureException(error, {
    tags: {
      errorCategory,
      requestUrl: error.config?.url,
      requestMethod: error.config?.method,
      isMobileSafari: isIOSSafari,
      connectionType: diagnostics.connectionType,
      connectionSpeed: diagnostics.connectionSpeed
    },
    extra: enhancedContext
  });
};

axios.interceptors.request.use(config => {
    if (!excludedEndpoints.some(endpoint => config.url.endsWith(endpoint))) {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Log network errors with enhanced context
    if (!error.response) {
      logEnhancedNetworkError(error, {
        originalRequest,
        retryCount: originalRequest?._retry
      });
      
      // Add custom error message for timeouts
      if (error.code === 'ECONNABORTED') {
        error.userFriendlyMessage = "The request is taking longer than expected. Please try again. Our team has been notified.";
      } else if (error.message === 'Network Error') {
        error.userFriendlyMessage = "We're having trouble connecting to our servers. Please try again.";
      }
    }

    if (
      error.response?.status === 401 &&
      error.response?.data?.msg === "Token has expired" &&
      !originalRequest._retry &&
      !excludedEndpoints.some(endpoint => originalRequest.url.endsWith(endpoint))
    ) {
      originalRequest._retry = true;
      try {
        const accessToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';

        // NOTE: we don't want this to appear in Sentry.
        //       However, we need to let it bubble up
        //       so we can filter out 401 errors.
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post('/auth/refresh', {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'Content-Type': 'application/json'
        }
      });
    localStorage.setItem('accessToken', response.data.access_token);
    return response.data.access_token;
}

export default axios;
