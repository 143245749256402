import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert, Checkbox, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { registerAccount } from '../api/auth';
import FortifyIcon from './FortifyIcon';
import ProgressBar from './shared/progressBar';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const CreateAccount = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const formattedPhoneNumber = formatPhoneNumber(value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const validateForm = () => {
    let tempErrors = {};
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(email)) tempErrors.email = "Please enter your email address.";
    if (firstName.length < 2) tempErrors.firstName = "Please enter your first name.";
    if (firstName.length > 30) tempErrors.firstName = "Please enter your first name.";
    if (lastName.length < 2) tempErrors.lastName = "Please enter your last name.";
    if (lastName.length > 30) tempErrors.lastName = "Please enter your last name.";
    if (phoneNumber.replace(/[^\d]/g, '').length !== 10) tempErrors.phoneNumber = "Please enter your phone number.";
    if (password.length < 8) tempErrors.password = "Your password must be at least 8 characters.";
    if (password.length > 64) tempErrors.password = "Please enter a shorter password."
    if (password !== confirmPassword) tempErrors.confirmPassword = "Your passwords do not match.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm() && termsAccepted) {
      setIsLoading(true);
      const queryParams = new URLSearchParams(location.search);
      const schoolId = queryParams.get('school');
      const programId = queryParams.get('program');
      const startDate = queryParams.get('start_date');

      if (!schoolId || !programId || !startDate) {
        setApiError('Missing school, program, or start date information.');
        setIsLoading(false);
        return;
      }

      try {
        const cleanedPhoneNumber = phoneNumber.replace(/[()\-\s]/g, '');
        const source = queryParams.get('source') || 'generic';
        const accountData = {
          email: email.toLowerCase(),
          phoneNumber: cleanedPhoneNumber,
          firstName,
          lastName,
          password,
          schoolId,
          programId,
          startDate,
          source,
        };
        const response = await registerAccount(accountData);
        navigate(`/application?loan_application_id=${response.loan_application_id}`);
      } catch (error) {
        setIsLoading(false);
        if (error?.response?.status === 400 && error?.response?.data?._schema?.[0]) {
          if (error?.response?.data?._schema?.[0].includes("already have a Fortify account under that email")) {
            navigate(`/login?email=${encodeURIComponent(email)}`);
          }
          setApiError(error?.response?.data?._schema?.[0]);
        } else {
          logErrorToConsole("Error creating account", error);
          logErrorToSentryWithContext(error, {
            email,
            phoneNumber,
            firstName,
            lastName,
            schoolId,
            programId,
            startDate,
          });

          setApiError(error?.userFriendlyMessage || error?.response?.data?.error || "There was an error creating your account. We're looking into it!");
        }
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const schoolParam = queryParams.get('school');
    const programParam = queryParams.get('program');
    const startDateParam = queryParams.get('start_date');
    if (!schoolParam || !programParam || !startDateParam || !uuidValidate(schoolParam) || !uuidValidate(programParam)) {
      navigate('/');
    }
  }, [navigate, location.search]);

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  return (
    <div className="application-background">
      <div className="application-container">
        <FortifyIcon />

        <div className="step-indicator-container text-center pb-8">
          <p className="text-md text-gray-500 mb-2 font-manrope">Apply to Fortify</p>
          <p className="step-descriptor">Step 1 of 2</p>
          <ProgressBar totalBars={2} filledBars={1} />
        </div>

        <div className='w-4/5 text-center'>
          <h1 className="card-title"><b>Create your account</b></h1>
        </div>

        <div className="flex flex-col space-y-4 w-full">
          <TextField 
            fullWidth 
            label="Email" 
            variant="outlined" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            error={!!errors.email}
            helperText={errors.email || ''}
          />

          <div className="inline-form-group">
            <TextField 
              fullWidth 
              label="First Name" 
              variant="outlined" 
              id="firstName" 
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)} 
              error={!!errors.firstName}
              helperText={errors.firstName || ''}
            />
            <TextField 
              fullWidth 
              label="Last Name" 
              variant="outlined" 
              id="lastName" 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)} 
              error={!!errors.lastName}
              helperText={errors.lastName || ''}
            />
          </div>

          <TextField 
            fullWidth 
            label="Phone Number" 
            variant="outlined" 
            id="phoneNumber" 
            value={phoneNumber} 
            onChange={handlePhoneNumberChange} 
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber || ''}
            inputProps={{ maxLength: 14 }}
          />

          <div className="inline-form-group">
            <TextField
              fullWidth
              className='sentry-mask'
              label="Password"
              variant="outlined"
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              className='sentry-mask'
              label="Confirm Password"
              variant="outlined"
              id="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className='flex flex-row items-start md:items-center'>
            <Checkbox
              style={{"paddingLeft": "0px"}}
              checked={termsAccepted}
              onChange={handleTermsChange}
              name="termsAccepted"
              disabled={isLoading}
            />
            <p className="text-xs text-legal-gray">
              By clicking 'Create account' or "Login with an existing account", you agree to our <a className="underline" target='_blank' href="https://fortifyedu.com/privacy">Privacy Policy</a>, our <a target='_blank' href="https://fortifyedu.com/tos" className='underline'>Terms of Service</a>, our <a target='_blank' href="https://fortifyedu.com/comms" className='underline'>Consent to Electronic Communications</a>, and our <a target='_blank' href="https://fortifyedu.com/credit-authorization" className='underline'>Credit Authorization Agreement</a>. You also authorize Fortify to send you text messages with account notifications and other services. Message & data rates may apply. Text us STOP at any time to unsubscribe.
            </p>
          </div>

          <div className="mt-8">
            {apiError && <Alert className='mx-auto text-center' style={{ maxWidth: '400px' }} severity="error">{apiError}</Alert>}
          </div>

          <div className='w-full text-center'>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div className='space-y-4'>
                <Button
                  variant="contained"
                  className='mt-8'
                  fullWidth
                  onClick={handleSubmit}
                  sx={{ height: '48px' }}
                  disabled={!termsAccepted}
                >
                  Create account
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleLoginRedirect}
                  className='mt-4'
                  sx={{ height: '48px' }}
                  disabled={!termsAccepted}
                >
                  Login with my existing account
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

