import React, { useState, useCallback, useEffect } from 'react';
import { Button, Box, Typography, Modal, IconButton, CircularProgress, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProgressBar from '../shared/progressBar';
import UploadIcon from '@mui/icons-material/CloudUpload';
import BankIcon from '@mui/icons-material/AccountBalance';
import { createBankSession, postBankItem, initiateStripeIdentityVerification, refreshStatusOfAssociatedIdentitySession, saveBankMetadata } from '../../api/student';
import { fetchLoanApplicationById } from '../../api/application';
import { usePlaidLink } from 'react-plaid-link';
import { loadStripe } from '@stripe/stripe-js';
import LoanApplicationData from '../../models/LoanApplicationData';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const InitiateIDVerification = ({ updateApplicationData, applicationData }) => {
  const { id: applicationId, studentId } = applicationData;

  const [stripeIdentityStatus, setStripeIdentityStatus] = useState(applicationData.student.stripeIdentityStatus || null);
  const [stripeIdentityError, setStripeIdentityError] = useState('');
  const [identityLoading, setIdentityLoading] = useState(false);

  const [bankVerificationStatus, setBankVerificationStatus] = useState(applicationData.student.plaidItemStatus || null);
  const [bankVerificationError, setBankVerificationError] = useState('');
  const [bankLoading, setBankLoading] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);

  const [bankFAQIsOpen, setBankFAQIsOpen] = useState(false);
  const [dontHaveABankAccountModalIsOpen, setDontHaveABankAccountModalIsOpen] = useState(false);

  const handleOpenBankFAQ = () => setBankFAQIsOpen(true);
  const handleCloseBankFAQ = () => setBankFAQIsOpen(false);
  const handleOpenDontHaveABankAccountModal = () => setDontHaveABankAccountModalIsOpen(true);
  const handleCloseDontHaveABankAccountModal = () => setDontHaveABankAccountModalIsOpen(false);

  const handleStripeIdentitySessionResponse = async (stripeSessionResponse) => {
    const { status, error } = stripeSessionResponse;

    switch (status) {
      case 'verified':
      case 'processing':
        const loanApplicationData = await fetchLoanApplicationById(applicationId);
        const newApplicationData = new LoanApplicationData(loanApplicationData);
        setStripeIdentityStatus(newApplicationData.student.stripeIdentityStatus);
        setStripeIdentityError('');
        updateApplicationData(newApplicationData);
        break;
      case 'requires_input':
      case 'cancelled':
      case 'error':
        setStripeIdentityError(error);
        break;
      default:
        setStripeIdentityError(error?.userFriendlyMessage || "Sorry, there was an error verifying your identity.");
        break;
    }

    setIdentityLoading(false);
  }

  const handleClickStripeIdentityVerification = async () => {
    setIdentityLoading(true);
    setStripeIdentityError('');
    try {
      const initiateIdentitySessionResponse = await initiateStripeIdentityVerification(studentId);

      // when creating the session, if they're already verified, short circuit it
      if (
        initiateIdentitySessionResponse.status
        && initiateIdentitySessionResponse.status === 'verified'
      ) {
        handleStripeIdentitySessionResponse(initiateIdentitySessionResponse);
        setIdentityLoading(false);
        return
      }

      const { client_secret } = initiateIdentitySessionResponse;
      const stripe = await stripePromise;
      await stripe.verifyIdentity(client_secret);

      const latestSessionStatusResponse = await refreshStatusOfAssociatedIdentitySession(studentId);
      handleStripeIdentitySessionResponse(latestSessionStatusResponse);
    } catch (error) {
      logErrorToConsole("Failed during Stripe identity verification", error);
      logErrorToSentryWithContext(error, {
        studentId,
      });

      setStripeIdentityError(error?.userFriendlyMessage || "Sorry, there was an error verifying your identity.");
      setIdentityLoading(false);
    }
  };

  const onPlaidSuccess = useCallback(async (publicToken, metadata) => {
    try {
      await postBankItem(studentId, publicToken, metadata);
      const loanApplicationData = await fetchLoanApplicationById(applicationId);
      const newApplicationData = new LoanApplicationData(loanApplicationData);

      setBankVerificationStatus(newApplicationData.student.plaidItemStatus);
      setBankVerificationError('');
      updateApplicationData(newApplicationData);
    } catch (error) {
      logErrorToConsole("Failed to exchange Plaid public token for access token", error);
      logErrorToSentryWithContext(error, {
        studentId,
        metadata,
      });

      setBankVerificationError(error?.userFriendlyMessage || error?.message || 'An error occurred while verifying your bank account');
    }
    setBankLoading(false);
  }, []);

  const onPlaidExit = useCallback(async (error, metadata) => {
    try {
      await saveBankMetadata(studentId, error, metadata);
    } catch (error) {
      logErrorToConsole("Failed to save Plaid metadata on exit", error);
      logErrorToSentryWithContext(error, {
        studentId,
        metadata,
      });
    }
    const errorMessage = error?.userFriendlyMessage || error?.display_message || 'Your bank verification was not completed. Please try again.';
    setBankVerificationError(errorMessage);
    setBankLoading(false);
  }, []);

  const handleClickBankVerification = async () => {
    setBankLoading(true);
    setBankVerificationError('');
    try {
      const response = await createBankSession(studentId);
      localStorage.setItem('plaidLinkToken', response.link_token);
      localStorage.setItem('studentId', studentId);
      setPlaidLinkToken(response.link_token);
    } catch (error) {
      logErrorToConsole("Failed to initiate bank verification", error);
      logErrorToSentryWithContext(error, {
        studentId,
      });
      setBankVerificationError(error?.userFriendlyMessage || error?.message || 'An error occurred while initiating bank verification');
      setBankLoading(false);
    }
  };

  const { open: openPlaidLink, ready: plaidLinkReady } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: onPlaidSuccess,
    onExit: onPlaidExit,
  });

  useEffect(() => {
    if (plaidLinkToken && plaidLinkReady) {
      setBankLoading(true);
      openPlaidLink();
    }
  }, [plaidLinkToken, plaidLinkReady, openPlaidLink]);

  const renderButton = (status, onClickHandler) => {
    let isDisabled, buttonColor, buttonText;

    if (status === 'verified') {
      isDisabled = true;
      buttonColor = "success";
      buttonText = "Verified";
    } else if (status === 'processing') { // Stripe intermediate state
      isDisabled = true;
      buttonColor = "success";
      buttonText = "Initiated";
    } else if (status === 'pending_automatic_verification') { // Plaid intermediate state
      isDisabled = true;
      buttonColor = "success";
      buttonText = "Initiated";
    } else {
      isDisabled = false;
      buttonColor = "primary";
      buttonText = "Verify";
    }

    return (
      <Button
        className="px-2 max-h-10"
        variant="contained"
        customPadding
        onClick={onClickHandler}
        disabled={isDisabled}
        color={buttonColor}
      >
        {buttonText}
      </Button>
    );
  };

  const totalStepsToCompleteLoan = (applicationData?.loan?.requiredDownPayment && parseFloat(applicationData?.loan?.requiredDownPayment) > 0) ? 3 : 2;
  
  return (
    <>
      <FortifyIcon />

      <div className="step-indicator-container text-center pb-8">
        <p className="text-md text-gray-500 mb-2 font-manrope">Complete your loan</p>
        <p className="step-descriptor">Step 1 of {totalStepsToCompleteLoan}</p>
        <ProgressBar totalBars={totalStepsToCompleteLoan} filledBars={1} />
      </div>

      <div className='w-full sm:w-4/5 lg:w-3/4 text-center'>
        <h1 className="pb-4 text-2xl font-manrope"><b>Verify Your Identity</b></h1>
        <p className="pb-6">Now that you're approved, we need to verify your identity before you can complete & sign your Fortify loan.</p>
      </div>

      <div className='w-full md:w-4/5 lg:w-3/4 flex flex-col space-y-8 text-center'>
        <div className='identity-action-box'>
            <div className='identity-action-detail'>
              <UploadIcon sx={{ fontSize: { xs: 45, sm: 55, md: 60 }, color: 'gray', flexShrink: 0 }} />
              <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: 2 }}>
                <Typography variant="h6" gutterBottom component="div" fontFamily="Manrope">
                  Upload your ID
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Please upload your government-issued photo ID, like a passport or driver's license.
                </Typography>
              </Box>
            </div>
            {identityLoading ? (
              <div className='mx-4'>
                <CircularProgress size={25}/>
              </div>
            ) : renderButton(stripeIdentityStatus, handleClickStripeIdentityVerification)}
        </div>

        {stripeIdentityError && (
          <div className="mt-8">
            <Alert className='mx-auto text-center' style={{ maxWidth: '400px' }} severity="error">{stripeIdentityError}</Alert>
          </div>
        )}

        <div className='identity-action-box'>
          <div className='identity-action-detail'>
            <BankIcon sx={{ fontSize: { xs: 45, sm: 55, md: 60 }, color: 'gray', flexShrink: 0 }} />
            <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: 2 }}>
              <Typography variant="h6" gutterBottom component="div" fontFamily="Manrope">
                Connect your bank
              </Typography>
              <p>
                You will <b>NOT</b> be charged at this time. Fortify uses your bank to verify your identity. <a onClick={(event) => { event.preventDefault(); handleOpenBankFAQ(); }} href="#" className="underline text-legal-gray">Read more</a>
              </p>
            </Box>
          </div>
          {bankLoading ? (
            <div className='mx-4'>
              <CircularProgress size={25}/>
            </div>
          ) : renderButton(bankVerificationStatus, handleClickBankVerification)}
        </div>

        {bankVerificationError && (
          <div className="mt-8">
            <Alert className='mx-auto text-center' style={{ maxWidth: '400px' }} severity="error">{bankVerificationError}</Alert>
          </div>
        )}

        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, cursor: 'pointer' }} onClick={handleOpenDontHaveABankAccountModal}>
          Don't have a bank account?
        </Typography>

      </div>

      <Modal
        open={bankFAQIsOpen}
        onClose={handleCloseBankFAQ}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc(100% - 60px)', sm: 400 },
          maxWidth: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}>
          <IconButton aria-label="close" onClick={handleCloseBankFAQ} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily="Manrope">
            Why does Fortify need my bank account? 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Fortify uses the bank account you provide to confirm your identity. Your bank account is <b>not charged</b> by linking it to Fortify.<br/><br/>Linking your bank account also makes it easier for you to set up autopay later and get a 0.25% interest rate discount on your loan, but setting up autopay is optional.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={dontHaveABankAccountModalIsOpen}
        onClose={handleCloseDontHaveABankAccountModal}
        aria-labelledby="no-bank-modal-title"
        aria-describedby="no-bank-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc(100% - 60px)', sm: 400 },
          maxWidth: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}>
          <IconButton aria-label="close" onClick={handleCloseDontHaveABankAccountModal} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="no-bank-modal-title" variant="h6" component="h2" fontFamily="Manrope">
            Don't have a bank account?
          </Typography>
          <Typography id="no-bank-modal-description" sx={{ mt: 2 }}>
            If you don't currently have a bank account, you can get one through our partner Chime by clicking <a href="https://member.chime.com/enroll" target="_blank"><u>here</u></a>.<br/><br/>If you already have a bank account, it's important that you use your existing account, so Fortify can properly verify your identity. Your bank will not be charged when you link it. 
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default InitiateIDVerification;
