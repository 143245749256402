import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Autocomplete, FormHelperText, Alert, CircularProgress } from '@mui/material';
import ProgressBar from '../shared/progressBar';
import { US_STATES } from '../../constants';
import { updateStudentInformation } from '../../api/student';
import LoanApplicationData from '../../models/LoanApplicationData';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const PersonalInformation = ({ applicationData, updateApplicationData }) => {
  const { id: applicationId, studentId } = applicationData;
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [dob, setDob] = useState('');
  const [residencyStatus, setResidencyStatus] = useState('');
  const [ssn, setSsn] = useState('');
  const [confirmSsn, setConfirmSsn] = useState('');
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);

  const streetAddressRef = useRef(null);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      try {
        const autocomplete = new window.google.maps.places.Autocomplete(streetAddressRef.current, {
          types: ['address'],
          componentRestrictions: { country: 'us' },
        });
      
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.address_components) {
            const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || '';
            const route = place.address_components.find(component => component.types.includes('route'))?.long_name || '';
            const locality = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';
            const administrativeAreaLevel1 = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
            const postalCode = place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '';

            setStreetAddress(`${streetNumber} ${route}`);
            setCity(locality);
            setState(administrativeAreaLevel1);
            setZip(postalCode);
          }
        });
      } catch (error) {
        logErrorToConsole("Error initializing Google Maps Places Autocomplete", error);
        logErrorToSentryWithContext(error);
      }
    }
  }, []);

  const formatSSN = (value) => {
    if (!value) return value;

    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;

    if (ssnLength < 4) return ssn;

    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }

    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  };

  const formatZip = (value) => {
    return value.replace(/[^\d]/g, '').slice(0, 5);
  };

  const validateForm = () => {
    let tempErrors = {};
    if (streetAddress.length < 3) tempErrors.streetAddress = "Please enter your street address.";
    if (city.length < 3) tempErrors.city = "Please enter your city.";
    if (!state) tempErrors.state = "Please select your state.";
    if (zip.length !== 5) tempErrors.zip = "Please enter your zip code.";
    if (!dob) tempErrors.dob = "Please enter your date of birth.";
    if (!residencyStatus) tempErrors.residencyStatus = "Please select your residency status.";
    if (new Set(ssn.replace(/-/g, '')).size < 3) tempErrors.ssn = "Please enter your real SSN to continue.";
    if (!ssn) tempErrors.ssn = "Please enter your SSN.";
    if (ssn !== confirmSsn) tempErrors.confirmSsn = "SSNs do not match.";

    const currentDate = new Date();
    const inputDateOfBirth = new Date(dob);
    const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));

    if (inputDateOfBirth > oneYearAgo) {
      tempErrors.dob = "Your birthday looks too recent. Please double check your birthday.";
    } else if (!dob) {
      tempErrors.dob = "Please enter your date of birth.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const studentData = {
        street: streetAddress,
        street_line_two: streetAddress2,
        city,
        state,
        zip,
        dob,
        ssn: ssn.replace(/-/g, ''),
        residency_status: residencyStatus,
      };
  
      try {
        const response = await updateStudentInformation(applicationId, studentId, studentData);
        const newApplicationData = new LoanApplicationData(response);
        updateApplicationData(newApplicationData);
      } catch (error) {
        if (error?.response?.data?._schema && Array.isArray(error.response.data._schema) && error.response.data._schema.length > 0) {
          setApiError(error.response.data._schema[0]);
        } else {
          logErrorToConsole("Failed to update student information", error);
          logErrorToSentryWithContext(error, {
            applicationId,
            studentId,
            studentData,
          });

          setApiError('An unexpected error occurred. Please try again.');
        }
        setLoading(false);
      }
    }
  };

  return (
    <>
      <FortifyIcon />

      <div className="step-indicator-container text-center pb-8">
        <p className="text-md text-gray-500 mb-2 font-manrope">Apply to Fortify</p>
        <p className="step-descriptor">Step 2 of 2</p>
        <ProgressBar totalBars={2} filledBars={2} />
      </div>

      <div className='w-4/5 text-center'>
        <h1 className="card-title"><b>Basic Information</b></h1>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <GoogleAutocomplete>
          <TextField
            fullWidth
            label="Street address"
            variant="outlined"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            error={!!errors.streetAddress}
            helperText={errors.streetAddress || ''}
            inputRef={streetAddressRef}
          />
        </GoogleAutocomplete>
        <TextField 
          fullWidth 
          label="Street address (line two)" 
          variant="outlined" 
          value={streetAddress2} 
          onChange={(e) => setStreetAddress2(e.target.value)}
        />
        <div className="inline-form-group">
          <TextField 
            fullWidth 
            label="City" 
            variant="outlined" 
            value={city} 
            onChange={(e) => setCity(e.target.value)} 
            error={!!errors.city}
            helperText={errors.city || ''}
          />
          <Autocomplete
            id="state-select"
            options={US_STATES}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="State" error={!!errors.state} helperText={errors.state || ''} />}
            value={state}
            onChange={(event, newValue) => {
              setState(newValue);
            }}
            fullWidth
          />
          <TextField 
            fullWidth 
            label="Zip" 
            variant="outlined" 
            value={zip} 
            onChange={(e) => setZip(formatZip(e.target.value))} 
            error={!!errors.zip}
            helperText={errors.zip || ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </div>
        <TextField 
          fullWidth 
          label="Date of Birth" 
          variant="outlined" 
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dob} 
          onChange={(e) => setDob(e.target.value)} 
          error={!!errors.dob}
          helperText={errors.dob || ''}
        />
        <FormControl fullWidth error={!!errors.residencyStatus}>
          <InputLabel id="residency-status-select-label">Residency Status</InputLabel>
          <Select
            labelId="residency-status-select-label"
            id="residency-status-select"
            value={residencyStatus}
            label="Residency Status"
            onChange={(e) => setResidencyStatus(e.target.value)}
            error={!!errors.residencyStatus}
          >
            <MenuItem value="us_citizen">US Citizen</MenuItem>
            <MenuItem value="permanent_resident">Permanent Resident</MenuItem>
            <MenuItem value="non_resident">Non-Resident</MenuItem>
          </Select>
          {!!errors.residencyStatus && <FormHelperText>{errors.residencyStatus}</FormHelperText>}
        </FormControl>
        <div className="inline-form-group">
          <TextField 
            fullWidth 
            className='sentry-mask'
            label="SSN" 
            variant="outlined" 
            value={ssn} 
            onChange={(e) => setSsn(formatSSN(e.target.value))} 
            error={!!errors.ssn}
            helperText={errors.ssn || ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]{3}-[0-9]{2}-[0-9]{4}' }}
          />
          <TextField 
            fullWidth 
            className='sentry-mask'
            label="Confirm SSN" 
            variant="outlined" 
            value={confirmSsn} 
            onChange={(e) => setConfirmSsn(formatSSN(e.target.value))} 
            error={!!errors.confirmSsn}
            helperText={errors.confirmSsn || ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]{3}-[0-9]{2}-[0-9]{4}' }}
          />
        </div>

        <div className="flex flex-row items-center pt-2 pb-4">
          <img src="/shield.svg" alt="Security shield" className="mr-2" />
          <p className="text-xs text-legal-gray">
            Your information is stored securely, with 256-bit SSL encryption. To read more, please see our <a className="underline" target='_blank' href="https://fortifyedu.com/privacy">Privacy Policy</a> and <a className="underline" target='_blank' href="https://fortifyedu.com/tos">Terms of Service</a>.
          </p>
        </div>

        {apiError && <Alert severity="error" style={{ marginBottom: '20px' }}>{apiError}</Alert>}
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '48px' }}
          >
            Continue 
          </Button>
        )}
      </form>
    </>
  );
};

export default PersonalInformation;
