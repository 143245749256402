import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

// Add HTMLDialogElement polyfill to prevent Google Maps API error
if (typeof window !== 'undefined' && !window.HTMLDialogElement) {
  window.HTMLDialogElement = function() {};
  
  // Add common methods that might be called on HTMLDialogElement
  window.HTMLDialogElement.prototype = {
    show: function() {},
    showModal: function() {},
    close: function() {},
    open: false
  };
}

// Initialize Sentry
Sentry.init({
  dsn: "https://3b290798f3d951e92948a72c5d0a1acf@o4507132225191936.ingest.us.sentry.io/4507132331360256",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  environment: process.env.REACT_APP_APP_ENV === "dev" ? "dev" : "prod",
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.fortifyedu\.com/],
  replaysSessionSampleRate: process.env.REACT_APP_APP_ENV === "dev" ? 0 : 0.20,
  replaysOnErrorSampleRate: 1.0,
});

export const enableSentryReplayIntegrationIfNotEnabled = () => {
  const client = Sentry.getClient();

  if (!client["_integrations"]["Replay"]) {
    Sentry.addIntegration(Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }));
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
