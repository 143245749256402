import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Button, TextField, Alert, CircularProgress } from '@mui/material';
import { updateLoanApplication, fetchLoanDetailsByTuition } from '../../api/application';
import FortifyIcon from '../FortifyIcon';
import { minTuitionFinanced } from '../../constants';
import LoanBreakdown from './LoanBreakdown';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const VerifyTuition = ({ applicationData, updateStage }) => {
  /* State variables */
  const [activeTuitionRequest, setActiveTuitionRequest] = useState(parseFloat(applicationData?.tuitionRequest));

  const [tuitionFinanced, setTuitionFinanced] = useState(null);
  const [originationFee, setOriginationFee] = useState(null);
  const [finalLoanAmount, setFinalLoanAmount] = useState(null);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [loanTerm, setLoanTerm] = useState(parseInt(applicationData?.loan?.loanTerm));

  const [localError, setLocalError] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [studentIsEditingTuition, setStudentIsEditingTuition] = useState(false);

  /* Local variables */
  const downPayment = parseFloat(applicationData?.loan?.requiredDownPayment) || 0;
  const interestRate = parseFloat(applicationData?.loan?.interestRate);
  const minTuitionRequest = downPayment + minTuitionFinanced;
  const programTuition = parseFloat(applicationData?.program?.tuition);
 
  /* Helper methods */
  const formatTuitionRequest = (value) => {
    if (!value) return '';
    const number = parseInt(value, 10);
    return isNaN(number) ? '' : `$${number.toLocaleString()}`;
  };

  const fetchPaymentDetails = async (tuition) => {
    try {
      const response = await fetchLoanDetailsByTuition(applicationData?.id, tuition)
      const data = await response;

      setServerError('');
      setTuitionFinanced(isNaN(parseFloat(data.tuition_financed)) ? null : parseFloat(data.tuition_financed));
      setOriginationFee(isNaN(parseFloat(data.origination_fee)) ? null : parseFloat(data.origination_fee));
      setFinalLoanAmount(isNaN(parseFloat(data.borrowing_amount)) ? null : parseFloat(data.borrowing_amount));
      setMonthlyPayment(isNaN(parseFloat(data.monthly_payment)) ? null : parseFloat(data.monthly_payment));
      setLoanTerm(isNaN(parseInt(data.loan_term)) ? null : parseInt(data.loan_term));
    } catch (error) {
      logErrorToConsole("Failed to fetch payment details", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData?.id,
        tuition,
      });

      const errorMessage = (error?.userFriendlyMessage || error?.response?.data?.error || 'Failed to fetch payment details. Please try again.');
      setServerError(errorMessage);
    }
  };

  const debouncedFetchPaymentDetails = debounce(fetchPaymentDetails, 50);

  const toggleEditing = () => {
    if (!studentIsEditingTuition) {
      setStudentIsEditingTuition(true)
    } else {
      setStudentIsEditingTuition(false)
      setActiveTuitionRequest(parseFloat(applicationData?.tuitionRequest))
      fetchPaymentDetails(parseFloat(applicationData?.tuitionRequest))
    }
  }

  const handleStudentTuitionRequestChange = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g,''), 10);

    setLocalError('');
    setServerError('');

    if (isNaN(value)) {
      setActiveTuitionRequest(null);
      setTuitionFinanced(null);
      setOriginationFee(null);
      setFinalLoanAmount(null);
      setMonthlyPayment(null);
      return;
    }

    setActiveTuitionRequest(value);

    if (value < minTuitionRequest || value > programTuition) {
      setLocalError(`Please enter an amount between $${minTuitionRequest.toLocaleString()} and $${programTuition.toLocaleString()}.`);
      setTuitionFinanced(null);
      setOriginationFee(null);
      setFinalLoanAmount(null);
      setMonthlyPayment(null);
    } else {
      debouncedFetchPaymentDetails(value);
    }
  };

  const handleSubmitTuitionRequest = async () => {
    let payload;

    if (activeTuitionRequest === schoolProvidedTuitionRequest) {
      payload = { "tuition_request_status": "approved" }
    } else {
      payload = { "tuition_request": activeTuitionRequest }
    }

    setServerError('');
    setIsLoading(true);
    try {
      const updatedApplicationData = await updateLoanApplication(applicationData.id, payload);
      updateStage(updatedApplicationData?.next_step);
    } catch (error) {
      logErrorToConsole("Failed to update tuition request from VerifyTuition", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData?.id,
        payload,
      });

      setIsLoading(false);
      setServerError(error?.userFriendlyMessage || error?.response.data[0] || 'Failed to proceed. Please try again.')
    }
  };

  /* Handle setting tuition request on page load */
  const schoolProvidedTuitionRequest = parseFloat(applicationData?.tuitionRequest);

  useEffect(() => {
    setActiveTuitionRequest(schoolProvidedTuitionRequest);
    debouncedFetchPaymentDetails(schoolProvidedTuitionRequest);
  }, []);

  const activeTuitionRequestIsInvalid = (!activeTuitionRequest || !!localError || !!serverError)

  return (
    <>
      <FortifyIcon />

      <p className='text-2xl pb-4 font-manrope'><b>Review your loan</b></p>
      
      <div className="w-full md:w-4/5 lg:w-2/3 mx-auto text-center">
        <p>Your school set your total remaining tuition to <b>${schoolProvidedTuitionRequest.toLocaleString()}</b>*. Please review your tuition breakdown and loan details:</p>

        <LoanBreakdown 
          tuitionRequest={activeTuitionRequest}
          downPayment={downPayment}
          tuitionFinanced={tuitionFinanced}
          originationFee={originationFee}
          finalLoanAmount={finalLoanAmount}
          monthlyPayment={monthlyPayment}
          interestRate={interestRate}
          loanTerm={loanTerm}
          activeTuitionRequestIsInvalid={activeTuitionRequestIsInvalid}
        />

        {serverError && (
          <div className="mt-4">
            <Alert style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }} severity="error">{serverError}</Alert>
          </div>
        )}

        {isLoading ? (
          <div className="my-8">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Button
              variant="outlined"
              className='w-full'
              style={{ marginTop: '20px', marginBottom: '12px' }}
              onClick={() => toggleEditing()}
            >
              {studentIsEditingTuition ? 'Reset' : 'Edit'}
            </Button>

            {studentIsEditingTuition && (
              <div className="mt-2 mb-4">
                <TextField 
                  type="text" 
                  value={formatTuitionRequest(activeTuitionRequest)}
                  onChange={handleStudentTuitionRequestChange}
                  error={!!localError}
                  helperText={localError}
                  variant="outlined"
                  label="Tuition Request"
                  InputProps={{ inputProps: { min: minTuitionRequest, max: programTuition } }}
                  fullWidth
                />
              </div>
            )}

            <Button
              variant="contained"
              className='w-full'
              style={{ backgroundColor: 'fortify-green' }}
              onClick={() => handleSubmitTuitionRequest()}
              disabled={!!localError}
            >
              Confirm
            </Button>

          </>
        )}
        
        <div className="text-left mt-2 sm:mt-4 text-legal-gray" style={{ fontSize: '0.75rem' }}>
          <p>
            *Changes to your tuition may also change your origination fee, your final loan amount, your monthly payment, your loan term, and your finance charge. Your interest rate is fixed and will not be affected by changes to your tuition.<br/><br/>Your school will need to confirm any changes you make to your tuition amount. Please carefully read the final disclosure in your loan packet, which will contain the final terms of your loan, before signing it.
          </p>
        </div>
      </div>
    </>
  )
};

export default VerifyTuition;
