import React, { useState } from 'react';
import { Button, Alert, CircularProgress } from '@mui/material';
import { updateLoanApplication, createApplicationFeeCheckoutSession } from '../../api/application';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const Approval = ({ applicationData, updateStage }) => {
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false); 

  const handleContinueApproval = async () => {
    try {
      setIsLoading(true);
      if (applicationData?.nextStep === 'application_fee' && applicationData?.requiredApplicationFee) {
        const response = await createApplicationFeeCheckoutSession(applicationData?.id);
        window.location.href = response.url;
      } else {
        const response = await updateLoanApplication(applicationData?.id, { loan_approval_acknowledged: true });
        updateStage(response.next_step);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      setServerError(error?.userFriendlyMessage || error?.response?.data[0] || error?.response?.data?.error || "There was an unexpected error. We're already looking into it, but please try again?");

      logErrorToConsole("Failed to continue after loan approval", error);
      if (!error?.response?.status || error?.response?.status !== 400) {
        logErrorToSentryWithContext(error, {
          applicationId: applicationData.id,
        });
      }
    }
  };

  const getNextStepMessage = () => {
    if (applicationData?.nextStep === 'application_fee' && applicationData?.requiredApplicationFee) {
      return "Next, you'll need to pay an application fee, verify your identity, and complete a few last steps to finalize your loan.";
    }
    return "Next, you'll just need to verify your identity with Fortify and complete a few last steps to finalize your loan.";
  };

  const getButtonText = () => {
    if (applicationData?.nextStep === 'application_fee' && applicationData?.requiredApplicationFee) {
      return `Pay $${parseFloat(applicationData.requiredApplicationFee).toFixed(2)} application fee`;
    }
    return 'Continue';
  };

  const getFooterText = () => {
    const lineOne = "Your approval is contingent on successfully paying your application fee to Fortify. This application fee is non-refundable and is required before you can proceed to finalize your loan agreement."
    const lineTwo = "Please note that the final tuition financed by your loan and the final size of your loan will depend on the tuition that your school chooses to finance through Fortify once they certify your application. If you have any questions about the amount you are approved for, please contact your school directly."
    const lineThree = "Before you can sign your loan, we may require you and your school to provide additional information to confirm your identity, verify your enrollment status, and determine the final loan amount you have been approved for. Your loan cannot be completed if you or your school do not provide the appropriate information or if your school does not certify your enrollment."

    return applicationData?.nextStep === 'application_fee' && applicationData?.requiredApplicationFee ? (
      <div>
        <p>*{lineOne}</p>
        <br/>
        <p>{lineTwo}</p>
        <br/>
        <p>{lineThree}</p>
      </div>
    ) : (
      <div>
        <p>*{lineTwo}</p>
        <br/>
        <p>{lineThree}</p>
      </div>
    );
  };

  return (
    <>
      <FortifyIcon />

      <div className='w-full md:w-4/5 lg:w-3/4 text-center'>
        <h1 className="text-2xl sm:text-3xl font-manrope"><b>Congratulations, <br/>you're approved!*</b></h1>

        <h2 className="my-8">{getNextStepMessage()}</h2>

        <div className="my-4">
          <img className="approval-image" src="/celebration.png" alt="Celebration"></img>
        </div>

        {serverError && (
          <div className="mt-4">
            <Alert style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }} severity="error">{serverError}</Alert>
          </div>
        )}

        <div className="mt-4">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button 
              variant="contained" 
              color="primary" 
              className='w-full'
              style={{ fontSize: '1rem', textTransform: 'none' }}
              onClick={handleContinueApproval}
            >
              {getButtonText()}
            </Button>
          )}
        </div>

        <div className='mt-4 sm:mt-8 text-left text-legal-gray' style={{ fontSize: '0.75rem' }}>
          {getFooterText()}
        </div>
      </div>
    </>
  );
};

export default Approval;
