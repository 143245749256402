import React, { useState, useEffect } from 'react';
import { Button, TextField, Autocomplete } from '@mui/material';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { fetchSchoolData } from '../api/schools';
import LoadingComponent from './LoadingComponent';
import DisclaimerModal from './DisclaimerModal';

import { addMonths, addDays } from 'date-fns';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const ProgramSelector = () => {
  const [programs, setPrograms] = useState({});
  const [source, setSource] = useState('generic');

  const [schoolName, setSchoolName] = useState('');
  const [schoolLogo, setSchoolLogo] = useState(null);

  const [schoolId, setSchoolId] = useState('');
  const [programId, setProgramId] = useState('');
  const [selectedProgram, setSelectedProgram] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  const { customApplicationLink } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlSchoolId = searchParams.get('school');

        if (!urlSchoolId && !customApplicationLink) {
          navigate('/login');
          return;
        }

        try {
          const { id, name: school_name, logo: school_logo, programs, is_cobranded } = await fetchSchoolData(urlSchoolId || customApplicationLink);

          setSource('direct');
          setPrograms(programs);
          setSchoolId(id);
          setSchoolName(school_name);
          if (school_logo && is_cobranded) {
            setSchoolLogo(school_logo);
          }

          if (programs.length === 1) {
            setProgramId(programs[0].id);
            setSelectedProgram(programs[0]);
          }
        } catch (error) {
          if (error?.response?.status === 404) {
            navigate('/login');
            return;
          }
          throw error;
        }
      } catch (error) {
        logErrorToConsole("Failed to fetch school", error);
        logErrorToSentryWithContext(error);
        navigate('/login');
        return;
      }
    };

    fetchData();
  }, [searchParams, navigate]);

  const handleProgramChange = (event, value) => {
    if (value) {
      setProgramId(value.id);
      setSelectedProgram(value);
    } else {
      setProgramId('');
      setSelectedProgram(null);
    }
  };

  const handleNextClick = () => {
    setIsDisclaimerModalOpen(true);
  };

  const handleProceedAfterDisclaimer = () => {
    setIsDisclaimerModalOpen(false);
    navigate(`/signup?school=${schoolId}&program=${programId}&start_date=${startDate}&source=${source}`);
  };

  const handleDeclineDisclaimer = () => {
    setIsDisclaimerModalOpen(false);
    window.location.reload();
  };

  const fortyFiveDaysFromNow = new Date();
  fortyFiveDaysFromNow.setDate(fortyFiveDaysFromNow.getDate() + 46);

  const validateStartDate = (value) => {
    const selectedProgram = programs?.find(program => program.id === programId);

    const defaultLengthInMonths = selectedProgram.default_length;
    const maximumStartDateDelayInDays = selectedProgram.maximum_delay_until_start_date_in_days;
    const minimumDistanceToGradDateInDays = selectedProgram.minimum_required_days_before_graduation;

    const shouldValidateGradDate = (defaultLengthInMonths > 2 && minimumDistanceToGradDateInDays !== 0)

    const today = new Date();
    const startDate = new Date(value + 'T00:00:00');
    const gradDate = addMonths(startDate, defaultLengthInMonths);

    const earliestAllowedStartDate = addMonths(addMonths(today, -defaultLengthInMonths), -2);
    const latestAllowedStartDate = addDays(today, maximumStartDateDelayInDays);

    const earliestAllowedGradDate = addDays(today, minimumDistanceToGradDateInDays);
    const latestAllowedGradDate = addMonths(addMonths(latestAllowedStartDate, defaultLengthInMonths), 2);

    const yearOfStartDate = startDate.getFullYear();

    if (yearOfStartDate < 2000) {
      setStartDateError('');
      return;
    }

    if (startDate < earliestAllowedStartDate) {
      setStartDateError(`Your start date is over ${defaultLengthInMonths + 2} months ago. Please contact Fortify support and we can help you proceed.`)
    } else if (startDate > latestAllowedStartDate) {
      setStartDateError(`Your start date cannot be more than ${maximumStartDateDelayInDays} days in the future. Please re-apply closer to your start date.`)
    } else if (
      gradDate < earliestAllowedGradDate
      && shouldValidateGradDate
    ) {
      setStartDateError(`It seems like you're too close to the end of your program. To get a Fortify loan, please contact support and we can help you proceed.`)
    } else if (
      gradDate > latestAllowedGradDate
    ) {
      setStartDateError(`Your graduation date is too far in the future. Please contact Fortify support and we can help you proceed.`)
    } else {
      setStartDateError('');
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    validateStartDate(event.target.value);
  };

  const startDateObject = new Date(startDate + 'T00:00:00');
  const startDateYear = startDateObject.getFullYear();

  if (
    (searchParams.get('school') || customApplicationLink) && !schoolId
  ) {
    return <LoadingComponent />
  }

  return (
    <div className="application-background">
      <div className="application-container">
        <div className="w-full h-20 flex justify-center">
          {schoolLogo ? (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <img style={{ width: '64px', height: '64px', borderRadius: '50%', position: 'absolute', left: "50%", transform: 'translateX(-48px)', display: 'flex', alignItems: 'center' }} src="/circle_logo.svg" alt="Fortify Logo" />
              <div style={{ width: '64px', height: '64px', borderRadius: '50%', border: '1px solid black', position: 'absolute', left: '50%', transform: 'translateX(0px)', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: '64px', height: '60px', borderRadius: '50%' }} src={schoolLogo} alt="School Logo" />
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <FortifyIcon />
            </div>
          )}
        </div>

        <div className='w-full sm:w-4/5 text-center'>
          <>
            <h1 className="text-2xl pb-1 font-manrope"><b>Apply to Fortify for</b></h1>
            <h1 className="card-title"><b>{schoolName}</b></h1>
            {programs.length === 1 && (
              <h2 className="pb-4">Program: <b>{programs[0].name}</b></h2>
            )}
          </>
          {programs.length !== 1 ? (
            <h2 className="pb-8">To get started, tell us about the program you're attending.</h2>
          ) : (
            <h2 className="pb-8">To get started, tell us your school start date.</h2>
          )}
        </div>
        <div className="flex flex-col space-y-5 w-full text-center">
          {programs.length !== 1 && (
            <Autocomplete
              id="program-select"
              options={programs || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Select Program" />}
              onChange={handleProgramChange}
              fullWidth
            />
          )}
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ max: fortyFiveDaysFromNow.toISOString().split('T')[0] }}
            value={startDate}
            onChange={handleStartDateChange}
            error={!!startDateError}
            helperText={startDateError}
            disabled={!programId}
          />

          <Button 
            variant="contained" 
            disabled={!programId || !startDate || !!startDateError || (startDateYear < 2000)} 
            color="primary"
            className="mt-8 w-full text-white lowercase"
            onClick={handleNextClick}
          >
            Next
          </Button>

          <DisclaimerModal
            open={isDisclaimerModalOpen}
            onClose={() => setIsDisclaimerModalOpen(false)}
            onProceed={handleProceedAfterDisclaimer}
            onDecline={handleDeclineDisclaimer}
            requiresDownPayment={selectedProgram?.down_payment_required}
            schoolId={schoolId}
            programId={programId}
          />

          <div
            className="w-full text-legal-gray text-sm mt-4 text-left space-y-2 md:space-y-1"
          >
            <p>
              Already signed up with Fortify? Log in <a href="/login" className="fortify-green" style={{ fontWeight: 'bold' }}><u>here</u></a>.
            </p>
            <p>
              If you have a question about Fortify, try our <a href="https://help.fortifyedu.com" className="fortify-green" style={{ fontWeight: 'bold' }}><u>help center</u></a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramSelector;